import styled from 'styled-components';

export const FormWrapper = styled.div`
  .form-group {
    margin-bottom: 20px;
  }

  .control-label {
    font-weight: bold;
    color: #333;
  }

  .form-control {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
`;
