import styled from 'styled-components';
import { colors } from '#styles.variables';
import { TypographyFamily } from '#ui-kit.types';

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-family: ${TypographyFamily.FactorA};
  font-size: 14px;
  color: ${colors.black._100};
  background-color: ${colors.primary._05};
  border-radius: 8px;
  padding: 10px;
`;

export const Checkbox = styled.input`
  margin-right: 8px;
`;
