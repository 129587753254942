import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { notAvailableValue } from '#utils';

import { FranchisorShopsTableComponentProps } from './types';

const FranchisorShopsTable: React.FC<FranchisorShopsTableComponentProps> = ({
  data,
  loading,
  currentPage,
  totalPages,
  onPageChange,
}) => (
  <>
    <Table
      {...{
        loading,
        header: {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  ID
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Наименование
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  ИНН
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Email
                </Typography>
              ),
            },
          },
        },
        rows: data?.map((listedFranchiserShop) => ({
          to: `/franchising/${listedFranchiserShop.id}`,
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(listedFranchiserShop.id)}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(listedFranchiserShop.fullName)}
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(listedFranchiserShop.tin)}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(listedFranchiserShop.shopEmail)}
                </Typography>
              ),
            },
          },
        })),
        currentPage,
        totalPages,
        onPageChange,
      }}
    />
  </>
);

export default FranchisorShopsTable;
