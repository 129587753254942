import styled from 'styled-components';
import { colors } from '#styles.variables';

// Основной контейнер для секций
export const Section = styled.section`
  margin-bottom: 24px;
`;

// Контейнер для строк ключ-значение
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0; /* Добавляем нижний разделитель */
`;

// Стиль для ключей (левая колонка)
export const Key = styled.div`
  font-weight: bold;
  color: #333;
  flex: 1;
  padding-right: 16px;
  text-align: left;
`;

// Стиль для значений (правая колонка)
export const Value = styled.div`
  flex: 2;
  text-align: right;
  color: #666;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #444;
`;

export const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  max-width: 200px;
  padding: 10px;
  background-color: ${colors.primary._05};
  text-align: center;
  border-radius: 8px;
`;
