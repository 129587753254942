import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { StatusBadge } from '#components';
import { notAvailableValue } from '#utils';
import { FranchisorAddressTypeEnumStatusBadgeMap } from 'pages/FranchiserShop/constants';
import { FranchisorAddressResponseDto, FranchisorAddressTypeEnum } from 'types';

type AddressesContainerProps = {
  data?: FranchisorAddressResponseDto[];
};

export const AddressesContainer: FC<AddressesContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Адреса
    </Typography>
    <Table
      header={{
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Тип адреса
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Почтовый индекс
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Трехбуквенный код страны по ISO
              </Typography>
            ),
          },
          4: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Город или населенный пункт
              </Typography>
            ),
          },
          5: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Улица, дом
              </Typography>
            ),
          },
          6: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Дополнительное описание
              </Typography>
            ),
          },
        },
      }}
      rows={
        data?.map((address, index) => ({
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Адрес {index + 1}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <StatusBadge<typeof FranchisorAddressTypeEnum>
                  {...{
                    value: address.type as FranchisorAddressTypeEnum,
                    data: FranchisorAddressTypeEnumStatusBadgeMap,
                  }}
                />
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(address.postalCode)}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(address.country)}
                </Typography>
              ),
            },
            4: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(address.city)}
                </Typography>
              ),
            },
            5: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(address.street)}
                </Typography>
              ),
            },
            6: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(address.description)}
                </Typography>
              ),
            },
          },
        })) || []
      }
    />
  </>
);
