import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue, prettifyDate } from '#utils';
import { FranchisorLicenseResponseDto } from 'types';

type LicensesContainerProps = {
  data?: FranchisorLicenseResponseDto[] | null;
};

export const LicensesContainer: FC<LicensesContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Лицензии
    </Typography>
    <Table
      header={{
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Вид лицензии
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Номер лицензии
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Дата выдачи
              </Typography>
            ),
          },
          4: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Кем выдана
              </Typography>
            ),
          },
          5: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Срок действия
              </Typography>
            ),
          },
          6: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Описание
              </Typography>
            ),
          },
        },
      }}
      rows={
        data?.map((license, index) => ({
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Лицензия {index + 1}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(license.type)}
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(license.number)}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(prettifyDate(license.issueDate ?? null))}
                </Typography>
              ),
            },
            4: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(license.issuedBy)}
                </Typography>
              ),
            },
            5: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(prettifyDate(license.expiryDate ?? null))}
                </Typography>
              ),
            },
            6: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(license.description)}
                </Typography>
              ),
            },
          },
        })) || []
      }
    />
  </>
);
