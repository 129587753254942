import React, { useState } from 'react';
import './Pagination.css';
import Typography from 'components/ui-kit/Typography';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';
import { colors } from '#styles.variables';

const Pagination: React.FC<{
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (pageNumber: number, pageSize: number) => void;
}> = ({ currentPage, totalPages, onPageChange, pageSize }) => {
  const [size, setSize] = useState<number>(pageSize);

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newSize = parseInt(event.target.value);
    setSize(newSize);
    // При изменении размера страницы сбрасываем текущую страницу на первую
    onPageChange(1, newSize);
  };

  const displayPages: any[] = [];

  const getPageNumbers = () => {
    const pagesToShow = 2; // Количество страниц, отображаемых до и после текущей страницы
    const sidePages = pagesToShow * 2; // Общее количество страниц до и после текущей

    if (totalPages <= sidePages) {
      // Если общее количество страниц меньше или равно sidePages, отображаем все страницы
      for (let i = 1; i <= totalPages; i++) {
        displayPages.push(i);
      }
    } else {
      // Иначе отображаем только часть страниц с добавлением многоточия
      const firstPage = Math.max(1, currentPage - pagesToShow);
      const lastPage = Math.min(totalPages, currentPage + pagesToShow);

      // Если первая страница больше 1, добавляем многоточие
      if (firstPage > 1) {
        displayPages.push(1);
        if (firstPage > 2) {
          displayPages.push('...');
        }
      }

      // Отображаем страницы от firstPage до lastPage
      for (let i = firstPage; i <= lastPage; i++) {
        displayPages.push(i);
      }

      // Если последняя страница меньше totalPages, добавляем многоточие
      if (lastPage < totalPages) {
        if (lastPage < totalPages - 1) {
          displayPages.push('...');
        }
        displayPages.push(totalPages);
      }
    }
  };

  getPageNumbers();

  return (
    <div className="pagination-container">
      <select
        className="pagination-select"
        value={size}
        onChange={handlePageSizeChange}
      >
        {[10, 20, 40].map((item) => (
          <option value={item}>{item}</option>
        ))}
      </select>
      <div className="pagination-block">
        <button
          className="pagination-button"
          onClick={() => onPageChange(currentPage - 1, size)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {displayPages.map((pageNumber, index) => {
          if (isNaN(parseInt(pageNumber))) {
            return (
              <Typography
                size={TypographySize.xxl}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
                style={{ margin: '0px 10px', color: colors.primary._100 }}
              >
                {pageNumber}
              </Typography>
            );
          }

          return (
            <button
              key={index}
              className={`pagination-button ${
                pageNumber === currentPage ? 'active' : ''
              }`}
              onClick={() => onPageChange(pageNumber, size)}
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          className="pagination-button"
          onClick={() => onPageChange(currentPage + 1, size)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
