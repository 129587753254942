import { FC } from 'react';
import { Button, Table, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { ButtonContainer } from 'pages/Documents/styles';
import { RestaurantResponse } from 'types';

type RestaurantSelectorProps = {
  restaurants: RestaurantResponse[];
  onSelect: (id: string) => Promise<void>;
  onClose: () => void;
  totalPages: number;
  onPageChange: (pageNumber: number, pageSize: number) => void;
  currentPage: number;
  isLoading: boolean;
};

export const RestaurantSelector: FC<RestaurantSelectorProps> = ({
  totalPages,
  onSelect,
  onPageChange,
  restaurants,
  currentPage,
  isLoading,
}) => (
  <Table
    {...{
      loading: isLoading,
      onPageChange,
      currentPage,
      totalPages,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Наименование
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Адрес ресторана
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
          },
        },
      },
      rows: restaurants?.map(({ id, name, address }) => ({
        cells: {
          0: {
            to: `/restaurants/${id}`,
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(name)}
              </Typography>
            ),
          },
          1: {
            to: `/restaurants/${id}`,
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(address)}
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <ButtonContainer>
                <Button
                  label="Привязать"
                  variant={ButtonVariant.Primary}
                  onClick={() => onSelect(id)}
                  size={ButtonSize.Small}
                  isLoading={isLoading}
                />
              </ButtonContainer>
            ),
          },
        },
      })),
    }}
  />
);
