import apiService from '#services/api';
import debug from '#services/debug.service';
import { ListedFranchisorShopResponseDto } from '../../types/dto/franchising/listed-franchisor-shop.response.dto';
import {
  CreateFranchisorShopRequestDto,
  FranchisorShopResponseDto,
  PaginationRequestDto,
  PaginationResponseDto,
  UpdateFranchisorShopRequestDto,
} from 'types';

export class FranchisingService {
  private readonly API_ROUTES = {
    getFranchisorShops: 'franchising',
    createFranchisorShop: 'franchising',
    updateFranchisorShop: (id: number) => `franchising/${id}`,
    getFranchisorShop: (id: number) => `franchising/${id}`,
    exportFranchisorShop: (id: number) => `franchising/${id}/export`,
    bindRestaurant: (restaurantId: string, franchisorShopId: number) =>
      `franchising/${franchisorShopId}/restaurants/${restaurantId}`,
  };

  public async exportFranchisorShop(
    franchisorShopId: number,
  ): Promise<FranchisorShopResponseDto> {
    try {
      const request = await apiService.request.put(
        this.API_ROUTES.exportFranchisorShop(franchisorShopId),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: FranchisorShopResponseDto = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ FranchisingService ~ exportFranchisorShop ~ result:',
        result,
      );

      return result;
    } catch (error: any) {
      debug.error(
        '🧑🏻‍💻 ~ FranchisingService ~ exportFranchisorShop ~ err:',
        error,
      );

      const response = await error?.response?.json();

      throw response;
    }
  }

  public async updateFranchisorShop(
    franchisorShopId: number,
    data: UpdateFranchisorShopRequestDto,
  ): Promise<FranchisorShopResponseDto> {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.updateFranchisorShop(franchisorShopId),
        {
          headers: {
            'content-type': 'application/json',
          },
          json: data,
        },
      );

      const result: FranchisorShopResponseDto = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ FranchisingService ~ updateFranchisorShop ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ FranchisingService ~ updateFranchisorShop ~ err:', err);

      throw err;
    }
  }

  public async createFranchisorShop(
    data: CreateFranchisorShopRequestDto,
  ): Promise<FranchisorShopResponseDto> {
    try {
      const request = await apiService.request.post(
        this.API_ROUTES.createFranchisorShop,
        {
          headers: {
            'content-type': 'application/json',
          },
          json: data,
        },
      );

      const result: FranchisorShopResponseDto = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ FranchisingService ~ createFranchisorShop ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ FranchisingService ~ createFranchisorShop ~ err:', err);

      throw err;
    }
  }

  public async getFranchisorShop(
    franchisorShopId: number,
  ): Promise<FranchisorShopResponseDto> {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.getFranchisorShop(franchisorShopId),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: FranchisorShopResponseDto = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ FranchisingService ~ getFranchisorShop ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ FranchisingService ~ getFranchisorShop ~ err:', err);

      throw err;
    }
  }

  public async getFranchisorShops(
    searchParams: PaginationRequestDto,
  ): Promise<PaginationResponseDto<ListedFranchisorShopResponseDto>> {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.getFranchisorShops,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams,
        },
      );

      const result: PaginationResponseDto<ListedFranchisorShopResponseDto> =
        await request.json();

      debug.success(
        '🧑🏻‍💻 ~ FranchisingService ~ getFranchisorShops ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ FranchisingService ~ getFranchisorShops ~ err:', err);

      throw err;
    }
  }
}
