import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { StatusBadge } from '#components';
import { notAvailableValue } from '#utils';

import { OrderPOSStatusEnum } from '#services/orders/enums/order-pos-status.enum';

import * as Styled from './style';
import { TableProps } from './types/Table.types';
import { OrderPOSStatusBadgeMap } from 'pages/Order/constants';
import { formatPrice } from 'utils/formatPrice';

const Form: React.FC<TableProps> = ({
  data,
  loading,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
}) => (
  <>
    <Table
      {...{
        loading,
        pageSize,
        header: {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Номер заказа
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Сумма заказа
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Дата, время заказа
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Статус заказа
                </Typography>
              ),
            },
            4: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Пользователь
                </Typography>
              ),
            },
          },
        },
        rows: data?.map(
          ({ id, orderNumber, user, totalPrice, createdAt, posStatus }) => {
            const date = new Date(createdAt);
            const formattedDate =
              date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
            return {
              to: `/orders/${id}`,
              cells: {
                0: {
                  width: 232,
                  align: TableCellAlign.Left,
                  onClick: () => {},
                  children: (
                    <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.span}
                      weight={TypographyWeight.Light}
                    >
                      {notAvailableValue(orderNumber)}
                    </Typography>
                  ),
                },
                1: {
                  width: 232,
                  align: TableCellAlign.Left,
                  onClick: () => {},
                  children: (
                    <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.span}
                      weight={TypographyWeight.Light}
                    >
                      {notAvailableValue(formatPrice(totalPrice))} ₽
                    </Typography>
                  ),
                },
                2: {
                  width: 232,
                  align: TableCellAlign.Left,
                  onClick: () => {},
                  children: (
                    <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.span}
                      weight={TypographyWeight.Light}
                    >
                      {notAvailableValue(formattedDate)}
                    </Typography>
                  ),
                },
                3: {
                  width: 232,
                  align: TableCellAlign.Left,
                  onClick: () => {},
                  children: (
                    <Styled.OrderStatusContainer>
                      <StatusBadge<typeof OrderPOSStatusEnum>
                        {...{
                          value: posStatus || 'NOT_CREATED',
                          data: OrderPOSStatusBadgeMap,
                        }}
                      />
                    </Styled.OrderStatusContainer>
                  ),
                },
                4: {
                  width: 232,
                  align: TableCellAlign.Right,
                  onClick: () => {},
                  children: (
                    <>
                      <Typography
                        size={TypographySize.s}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        {`${notAvailableValue(user?.name)}, 
                        ${notAvailableValue(user?.phoneNumber)}`}
                      </Typography>
                    </>
                  ),
                },
              },
            };
          },
        ),
        currentPage,
        totalPages,
        onPageChange,
      }}
    />
  </>
);

export default Form;
