import debug from '#services/debug.service';
import apiService from '../api';
import { UploadBoardDto } from './dto/upload-board.dto';
import { GetDeliveryZonesDto } from 'types';

export class OnboardingService {
  private readonly API_ROUTES = {
    onboarding: 'onboarding',
    onboardingPost: 'admin/onboarding',
    onboardingById: (id: number) => `admin/onboarding/${id}`,
  };

  public async getOnboarding(searchParams: GetDeliveryZonesDto) {
    try {
      const request = await apiService.request.get(this.API_ROUTES.onboarding, {
        headers: {
          'content-type': 'application/json',
        },
        searchParams,
      });

      const result: any = await request.json();

      debug.success('🧑🏻‍💻 ~ OnboardingService ~ getOnboarding ~ result:', result);

      return result.data;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OnboardingService ~ getOnboarding ~ err:', err);

      throw err;
    }
  }

  public async postOnboarding({ body }: any) {
    try {
      await apiService.request.post(this.API_ROUTES.onboardingPost, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      debug.success('🧑🏻‍💻 ~ OnboardingService ~ postOnboarding ~ result: SUCCES');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OnboardingService ~ postOnboarding ~ err:', err);

      throw err;
    }
  }

  public async updateOnboardingById({ data, onboardId }: UploadBoardDto) {
    try {
      const request = await apiService.request.patch(
        `${this.API_ROUTES.onboardingById(onboardId)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      const result: any = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ OnboardingService ~ updateOnboardingById ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OnboardingService ~ updateOnboardingById ~ err:', err);
      throw err;
    }
  }
}
