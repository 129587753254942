import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  max-width: 150px;
`;
