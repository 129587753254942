import { buildQueryFromObject } from '#utils';
import apiService from '#services/api';
import debug from '#services/debug.service';

import { GetOrdersDto } from './dto/get-orders.dto';
import { RawUpdateOrderDto } from './dto/raw-update-order.dto';
import { RefundOrderDto } from './dto/refund-order.dto';
import { OrderResponse } from './responses/order.response';
import { OrdersResponse } from './responses/orders.response';

export { InternalStatusEnum } from './responses/orders.response';

export class OrdersService {
  private readonly API_ROUTES = {
    orders: 'admin/orders/',
    order: (id: string) => `v2/admin/orders/${id}`,
    cancelOrder: (id: number) => `orders/${id}/cancel/`,
    refundOrder: (id: number) => `admin/orders/${id}/refund/`,
    rawUpdateOrder: (id: number) => `v2/admin/orders/${id}`,
  };

  public async getOrders(searchParams: GetOrdersDto) {
    const queryString = buildQueryFromObject(searchParams);

    try {
      const request = await apiService.request.get(
        this.API_ROUTES.orders + queryString,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: OrdersResponse = await request.json();

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OrdersService ~ getOrders ~ err:', err);
      throw err;
    }
  }

  public async getOrderById(id: string) {
    try {
      const request = await apiService.request.get(this.API_ROUTES.order(id), {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result: OrderResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ OrdersService ~ getOrderById ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OrdersService ~ getOrderById ~ err:', err);

      throw err;
    }
  }

  public async refundOrder(
    id: number,
    body: RefundOrderDto,
  ): Promise<OrderResponse> {
    try {
      const request = await apiService.request.put(
        this.API_ROUTES.refundOrder(id),
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );

      const result = await request.json<OrderResponse>();

      debug.success('🧑🏻‍💻 ~ OrdersService ~ refundOrder ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OrdersService ~ refundOrder ~ err:', err);

      throw err;
    }
  }

  public async rawUpdateOrder(
    id: number,
    body: RawUpdateOrderDto,
  ): Promise<OrderResponse> {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.rawUpdateOrder(id),
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ...body,
            osType: 'OTHER',
          }),
        },
      );

      const result: OrderResponse = await request.json();

      console.log(result);

      debug.success('🧑🏻‍💻 ~ OrdersService ~ rawUpdateOrder ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OrdersService ~ rawUpdateOrder ~ err:', err);

      throw err;
    }
  }

  public async cancelOrder(id: number, osType: string) {
    try {
      await apiService.request.post(this.API_ROUTES.cancelOrder(id), {
        headers: {
          'content-type': 'application/json',
        },
        searchParams: {
          osType,
        },
      });

      debug.success('🧑🏻‍💻 ~ OrdersService ~ cancelOrder');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ OrdersService ~ cancelOrder ~ err:', err);

      throw err;
    }
  }
}
