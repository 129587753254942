import { buildQueryFromObject } from '#utils';
import apiService from '#services/api';
import debug from '#services/debug.service';
import {
  DeliveryZoneListResponse,
  GetDeliveryZonesDto,
  GetRestaurantsDto,
  RestourantsResponse,
  UpdateRestaurantRequestDto,
  UploadDeliveryZoneDto,
} from 'types';

export class RestaurantsService {
  private readonly API_ROUTES = {
    restaurants: 'restaurants/',
    deliveryZone: 'restaurants/delivery-zones/',
    updateRestaurant: (restaurantId: string) => `restaurants/${restaurantId}`,
  };

  public async updateRestaurant(
    restaurantId: string,
    data: UpdateRestaurantRequestDto,
  ) {
    try {
      await apiService.request.patch(
        this.API_ROUTES.updateRestaurant(restaurantId),
        {
          headers: {
            'content-type': 'application/json',
          },
          json: data,
        },
      );

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ updateRestaurant ~ result: success',
      );
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ updateRestaurant ~ err:', err);

      throw err;
    }
  }

  public async getDeliveryZones(searchParams: GetDeliveryZonesDto) {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.deliveryZone,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams,
        },
      );

      const result: DeliveryZoneListResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ getDeliveryZones ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ getDeliveryZones ~ err:', err);

      throw err;
    }
  }

  public async uploadDeliveryZones(body: UploadDeliveryZoneDto) {
    try {
      await apiService.request.post(this.API_ROUTES.deliveryZone, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ uploadDeliveryZones ~ result: SUCCES',
      );
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ uploadDeliveryZones ~ err:', err);

      throw err;
    }
  }

  public async getRestaurants(searchParams: GetRestaurantsDto) {
    const stringParams = buildQueryFromObject(searchParams);
    console.log(stringParams);
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.restaurants,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams: stringParams,
        },
      );

      const result: RestourantsResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ getRestaurants ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ getRestaurants ~ err:', err);

      throw err;
    }
  }
}
