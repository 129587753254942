import * as Yup from 'yup';
import { isNullableValue } from '#utils';
import {
  CreateFranchisorShopRequestDto,
  FranchisorAddressTypeEnum,
  FranchisorPhoneTypeEnum,
} from 'types';

// Схема валидации формы с использованием Yup
export const validationSchema = Yup.object().shape({
  billingDescriptor: Yup.string().required('Обязательное поле'),
  fullName: Yup.string().required('Обязательное поле'),
  acea: Yup.string().nullable(),
  msrn: Yup.string().required('Обязательное поле'),
  registrationDepartment: Yup.string().nullable(),
  registrationDate: Yup.string().nullable(),
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required('Обязательное поле'),
        postalCode: Yup.string().nullable(),
        country: Yup.string().nullable(),
        city: Yup.string().nullable(),
        street: Yup.string().required('Обязательное поле'),
        description: Yup.string().nullable(),
      }),
    )
    .min(1, 'Требуется минимум один адрес'),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required('Обязательное поле'),
        phone: Yup.string().required('Обязательное поле'),
        description: Yup.string().nullable(),
      }),
    )
    .min(1, 'Требуется минимум один телефон'),
  receivingEmail: Yup.string()
    .email('Некорректный email')
    .required('Обязательное поле'),
  assets: Yup.string().nullable(),
  founders: Yup.object()
    .shape({
      individuals: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required('Обязательное поле'),
          lastName: Yup.string().required('Обязательное поле'),
          middleName: Yup.string().nullable(),
          birthDate: Yup.string().nullable(),
          birthPlace: Yup.string().nullable(),
          citizenship: Yup.string().required('Обязательное поле'),
          docType: Yup.string().nullable(),
          docNumber: Yup.string().nullable(),
          issueDate: Yup.string().nullable(),
          issuedBy: Yup.string().nullable(),
          address: Yup.string().required('Обязательное поле'),
        }),
      ),
    })
    .nullable(),
  ceo: Yup.object()
    .shape({
      firstName: Yup.string().required('Обязательное поле'),
      lastName: Yup.string().required('Обязательное поле'),
      middleName: Yup.string().nullable(),
      birthDate: Yup.string().required('Обязательное поле'),
      birthPlace: Yup.string().nullable(),
      docType: Yup.string().nullable(),
      docNumber: Yup.string().nullable(),
      issueDate: Yup.string().nullable(),
      issuedBy: Yup.string().nullable(),
      address: Yup.string().nullable(),
      phone: Yup.string().required('Обязательное поле'),
      country: Yup.string().required('Обязательное поле'),
    })
    .required('Обязательное поле'),
  licenses: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().nullable(),
        number: Yup.string().nullable(),
        issueDate: Yup.string().nullable(),
        issuedBy: Yup.string().nullable(),
        expiryDate: Yup.string().nullable(),
        description: Yup.string().nullable(),
      }),
    )
    .nullable(),
  url: Yup.string().url('Некорректный URL').required('Обязательное поле'),
  primaryActivities: Yup.string().nullable(),
  bankAccount: Yup.object()
    .shape({
      account: Yup.string().required('Обязательное поле'),
      korAccount: Yup.string().nullable(),
      bankName: Yup.string().required('Обязательное поле'),
      bic: Yup.string()
        .length(9, 'Длина БИК должна составлять 9 символов')
        .required('Обязательное поле'),
      details: Yup.string().required('Обязательное поле'),
      bcc: Yup.string().nullable(),
      actme: Yup.string().nullable(),
      tax: Yup.number().required('Обязательное поле'),
      taxFix: Yup.number().nullable(),
    })
    .required('Обязательное поле'),
  comment: Yup.string().nullable(),
  fiscalization: Yup.object()
    .shape({
      //   company: Yup.string().oneOf(['OrangeData']).required('Обязательное поле'),
      notifyUrl: Yup.string().url('Некорректный URL'),
    })
    .nullable(),
  shopEmail: Yup.string().email('Некорректный email'),
  mcc: Yup.number().nullable(),
  name: Yup.string().required('Обязательное поле'),
  tin: Yup.string().required('Обязательное поле'),
  rrc: Yup.string()
    .transform((value) => (isNullableValue(value) ? '' : value))
    .required('Обязательное поле'),
  isResident: Yup.boolean(),
});

// Начальные значения формы
export const initialValues: CreateFranchisorShopRequestDto = {
  billingDescriptor: '',
  fullName: '',
  acea: '',
  msrn: '',
  registrationDepartment: '',
  registrationDate: '',
  addresses: [{ type: FranchisorAddressTypeEnum.ACTUAL, street: '' }],
  phones: [{ type: FranchisorPhoneTypeEnum.COMMON, phone: '' }],
  receivingEmail: '',
  assets: '',
  founders: {
    individuals: [],
  },
  ceo: {
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: '',
    birthPlace: '',
    docType: '',
    docNumber: '',
    issueDate: '',
    issuedBy: '',
    address: '',
    phone: '',
    country: '',
  },
  licenses: [],
  url: '',
  primaryActivities: '',
  bankAccount: {
    account: '',
    korAccount: '',
    bankName: '',
    bic: '',
    details: '',
    bcc: '',
    actme: '',
    tax: 0,
    taxFix: null,
  },
  comment: '',
  fiscalization: {
    сompany: 'OrangeData',
    notifyUrl: '',
  },
  // Поля из FranchisorShopDataModel
  shopEmail: '',
  mcc: null,
  name: '',
  tin: '',
  rrc: '',
  isResident: true,
};
