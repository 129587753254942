export type OrdersResponse = {
  data: OrdersType;
  isLast: boolean;
  page: number;
  size: number;
  total: number;
};

export type OrdersType = {
  id: number;
  orderNumber: string;
  createdAt: Date;
  totalPrice: number;
  internalStatus: InternalStatusEnum;
  posStatus: ExternalStatusEnum;
  user: {
    phoneNumber: string;
    name: string;
  };
}[];

export enum ExternalStatusEnum {
  UNKNOWN = 'UNKNOWN',
  NEW = 'NEW',
  ACCEPTED_BY_RESTAURANT = 'ACCEPTED_BY_RESTAURANT',
  POSTPONED = 'POSTPONED',
  COOKING = 'COOKING',
  READY = 'READY',
  TAKEN_BY_COURIER = 'TAKEN_BY_COURIER',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum InternalStatusEnum {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}
