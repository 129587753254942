import { useRef } from 'react';

import { toastify } from '#ui-kit';

import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import FilesService from '#services/files/files.service';
import { RestaurantsService } from '#services/restaurants';

import Table from './containers/Table';

import useDeliveryZones from 'hooks/useDeliveryZones';
import { DeliveryZoneListResponse } from 'types';

export default () => {
  const filesService = new FilesService();
  const restaurantsService = new RestaurantsService();

  const [data, getDeliveryZones, setData, { generalLoading, loading }] =
    useDeliveryZones();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileObj = event?.target?.files && event?.target?.files[0];

    if (!fileObj) {
      return;
    }

    const filename = fileObj.name;
    const extension = filename.slice(filename.lastIndexOf('.')).toLowerCase();

    try {
      if (extension === '.kml') {
        const file = await filesService.uploadFile(fileObj);
        await restaurantsService.uploadDeliveryZones({
          id: file.id,
        });
        const deliveryZones = (await getDeliveryZones(
          true,
        )) as DeliveryZoneListResponse;
        setData(deliveryZones);
        toastify.success('Зоны доставки успешно обновлены.');
      } else {
        toastify.error('Не удалось загрузить файл. Неверное расширение.');
      }
    } catch {
      toastify.error('Не удалось обновить зоны доставки.');
    }
  };

  const fileInput = () => (
    <input
      style={{ display: 'none' }}
      ref={inputRef}
      type="file"
      onChange={handleFileChange}
    />
  );

  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  return (
    <>
      {fileInput()},
      <Head
        {...{
          label: 'Зоны доставки',
          underlineVariant: HeadUnderlineVariant.Primary,
          button: {
            label: 'Обновить зоны доставки',
            onClick: handleButtonClick,
          },
        }}
      />
      <Table {...{ data, loading }} />
    </>
  );
};
