import { FC, useEffect, useState } from 'react';
import { toastify, Typography } from '#ui-kit';
import { TypographySize, TypographyTag } from '#ui-kit.types';
import { ErrorsService } from '#services/errors';
import { GetOrdersDto } from '#services/orders/dto/get-orders.dto';
import { OrderInternalStatusEnum } from '#services/orders/enums/order-internal-status.enum';
import { ExternalStatusEnum } from '#services/orders/responses/orders.response';
import { CheckboxGroup } from '../CheckboxGroup';
import { OrderInternalStatusLabels, OrderPOSStatusLabels } from './constants';
import { FormContainer, FormItem, SelectedErrorLabel } from './style';
import { colors, Input, Label, Section } from 'styles';
import { LanguageISO2Enum } from 'types';

type FilterFormPropsType = {
  handleCheckboxGroupChange: (name: string, selectedValues: string[]) => void;
  handleFormSubmit: (event: React.FormEvent) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteSelectedError: (name: string) => void;
  filters: Omit<GetOrdersDto, 'page' | 'size'>;
};

export const FilterForm: FC<FilterFormPropsType> = ({
  handleCheckboxGroupChange,
  handleFormSubmit,
  handleInputChange,
  handleDeleteSelectedError,
  filters,
}) => {
  const [errorsTranslations, setErrorsTranslations] = useState<
    Record<string, string>
  >({});
  const [errors, setErrors] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const errorsService = new ErrorsService();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchString(event.target.value);

  const getErrors = async () => {
    try {
      const result = await errorsService.getFilteredErrors({
        page: 1,
        size: 10,
        language: LanguageISO2Enum.RU,
        searchString,
      });

      setErrorsTranslations((old) => {
        for (const error of result.data) {
          old[error.code] = error.message;
        }

        return old;
      });

      const errorCodes = result.data.map((error) => error.code);

      setErrors(errorCodes);
    } catch {
      toastify.error('Не удалось получить список ошибок');
    }
  };

  useEffect(() => {
    getErrors();
  }, [searchString]);

  return (
    <Section>
      <FormContainer onSubmit={handleFormSubmit}>
        {/* Номер заказа */}
        <FormItem>
          <Label htmlFor="orderNumber">Номер заказа</Label>
          <Input
            type="text"
            id="orderNumber"
            name="orderNumber"
            value={filters.orderNumber || ''}
            onChange={handleInputChange}
          />
        </FormItem>

        {/* Номер телефона пользователя */}
        <FormItem>
          <Label htmlFor="userPhoneNumber">Номер телефона пользователя</Label>
          <Input
            type="text"
            id="userPhoneNumber"
            name="userPhoneNumber"
            value={filters.userPhoneNumber || ''}
            onChange={handleInputChange}
          />
        </FormItem>

        {/* Дата создания с */}
        <FormItem>
          <Label htmlFor="createdAtFrom">Дата создания с</Label>
          <Input
            type="date"
            id="createdAtFrom"
            name="createdAtFrom"
            value={filters.createdAtFrom || ''}
            onChange={handleInputChange}
          />
        </FormItem>

        {/* Дата создания по */}
        <FormItem>
          <Label htmlFor="createdAtTo">Дата создания по</Label>
          <Input
            type="date"
            id="createdAtTo"
            name="createdAtTo"
            value={filters.createdAtTo || ''}
            onChange={handleInputChange}
          />
        </FormItem>

        {/* Внутренний статус */}
        <FormItem style={{ flexBasis: '100%' }}>
          {' '}
          {/* Занимает всю ширину */}
          <Label>Внутренний статус</Label>
          <CheckboxGroup
            name="internalStatus"
            options={Object.values(OrderInternalStatusEnum)}
            translations={OrderInternalStatusLabels}
            selectedOptions={filters.internalStatus || []}
            onChange={handleCheckboxGroupChange}
          />
        </FormItem>

        {/* Внешний статус */}
        <FormItem style={{ flexBasis: '100%' }}>
          {' '}
          {/* Занимает всю ширину */}
          <Label>Внешний статус</Label>
          <CheckboxGroup
            name="posStatus"
            options={Object.values(ExternalStatusEnum)}
            translations={OrderPOSStatusLabels}
            selectedOptions={filters.posStatus || []}
            onChange={handleCheckboxGroupChange}
          />
        </FormItem>

        <Section>
          <Label>Выбранные ошибки</Label>
          {filters.errorMessageCodes?.length ? (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {filters.errorMessageCodes.map((error) => (
                <SelectedErrorLabel
                  onClick={() => handleDeleteSelectedError(error)}
                >
                  {'⛌ ' + errorsTranslations[error]}
                </SelectedErrorLabel>
              ))}
            </div>
          ) : (
            <Typography
              size={TypographySize.xs}
              tag={TypographyTag.p}
              style={{
                color: `${colors.black._100}`,
              }}
            >
              Ничего не выбрано
            </Typography>
          )}
        </Section>

        {/* Причина отмены */}
        <FormItem style={{ flexBasis: '100%' }}>
          <Label>Поиск ошибок</Label>
          <Input
            type="text"
            id="searchString"
            name="searchString"
            value={searchString}
            onChange={handleSearchChange}
            style={{ marginBottom: '10px' }}
          />
          <CheckboxGroup
            name="errorMessageCodes"
            options={errors}
            selectedOptions={filters.errorMessageCodes || []}
            onChange={handleCheckboxGroupChange}
            translations={errorsTranslations}
          />
        </FormItem>
      </FormContainer>
    </Section>
  );
};
