import React, { useState } from 'react';
import debug from '#services/debug.service';
import { LoadingContent } from '../../../components/common';
import { Button, toastify } from '../../../components/ui-kit';
import { OnboardingService } from '../../../services/onbording';
import type { OnboardingDataType } from '../../../services/onbording/dto/upload-board.dto';
import { PromotionStatusEnum } from '../../../services/promotions/dto/get-promotions.dto';
import ReactSwitch from 'react-switch';

type PropsType = {
  item: OnboardingDataType;
};

const OnboardingItem: React.FC<PropsType> = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [dataPr, setDataPr] = useState(item);
  const onboardingService = new OnboardingService();
  const [checked, setChecked] = useState(
    dataPr.status === PromotionStatusEnum.ACTIVE,
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const onboardId = dataPr?.id;

  const handleChange = (newChecked: boolean) => {
    setChecked(newChecked);
    setIsSaveDisabled(!isSaveDisabled);
  };

  const updateOnboardingById = async (shouldLoad = true) => {
    const data = {
      ...dataPr,
      status: checked
        ? PromotionStatusEnum.ACTIVE
        : PromotionStatusEnum.DISABLED,
    };
    try {
      if (shouldLoad) {
        setLoading(true);
      }
      const response = await onboardingService.updateOnboardingById({
        data,
        onboardId,
      });

      setDataPr(response);
    } catch (e) {
      debug.error('updateOnboardingById is error: ', e);
      toastify.error('Произошла ошибка при изменеии данных');
    } finally {
      if (shouldLoad) {
        setLoading(false);
      }
    }
  };

  const handleSave = () => {
    updateOnboardingById();
    setIsSaveDisabled(!isSaveDisabled);
  };

  return (
    <>
      <div className="onbording_wrap">
        <label htmlFor="material-switch">
          <span>{item.description}</span>
          <ReactSwitch
            checked={checked}
            onChange={handleChange}
            onColor="#ECF4DA"
            onHandleColor="#9EC948"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id={item.action.productId}
          />
        </label>
      </div>
      {loading ? (
        <LoadingContent />
      ) : (
        <Button
          label="Сохранить изменения"
          onClick={handleSave}
          type="button"
          disabled={isSaveDisabled}
        />
      )}
    </>
  );
};

export default OnboardingItem;
