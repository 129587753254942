export function buildQueryFromObject<T extends object>(data: T): string {
  const result: string[] = [];

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((val) => result.push(`${key}=${val}`));
      continue;
    }

    result.push(`${key}=${value}`);
  }

  return '?' + result.join('&');
}
