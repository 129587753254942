import { useEffect, useState } from 'react';
import { Modal } from '#ui-kit';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import debug from '#services/debug.service';
import { FranchisingService } from '#services/franchising';
import FranchisorShopsTable from './containers/Table';
import FranchisorShopForm from 'pages/FranchiserShop/containers/Form';
import { ListedFranchisorShopResponseDto } from 'types';

export default () => {
  const [franchisorShops, setFranchisorShops] = useState<
    ListedFranchisorShopResponseDto[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const franchisingService = new FranchisingService();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const getFranchisorShops = async () => {
    try {
      setLoading(true);

      const params = {
        page: page,
        size: size,
      };

      const response = await franchisingService.getFranchisorShops(params);

      setFranchisorShops((old) => {
        if (page === 1) {
          return response.data;
        } else {
          return [...old, ...response.data];
        }
      });

      setTotalPages(Math.ceil(response.total / response.size));
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getFranchisorShops ~ err:', e);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = async () => {
    setIsModalOpen(false);

    await getFranchisorShops();
  };

  useEffect(() => {
    getFranchisorShops();
  }, [page, size]);

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <FranchisorShopForm onClose={handleCloseModal} />
      </Modal>
      <Head
        {...{
          label: 'Магазины франчайзеров',
          underlineVariant: HeadUnderlineVariant.Primary,
          button: {
            label: 'Создать партнерскую точку',
            onClick: handleOpenModal,
          },
        }}
      />
      {loading && page === 1 ? (
        <LoadingContent />
      ) : (
        <FranchisorShopsTable
          data={franchisorShops}
          loading={loading}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
