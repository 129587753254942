import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue, prettifyDate } from '#utils';
import { FranchisorCEOResponseDto } from 'types';

type CeoContainerProps = {
  data?: FranchisorCEOResponseDto;
};

export const CeoContainer: FC<CeoContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Сведения о руководителе
    </Typography>
    <Table
      header={{
        cells: {},
      }}
      rows={[
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Имя
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.firstName)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Фамилия
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.lastName)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Отчество
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.middleName)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Дата рождения
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(prettifyDate(data?.birthDate ?? null))}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Место рождения
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.birthPlace)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Вид документа, удостоверяющего личность
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.docType)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Серия и номер документа
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.docNumber)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Дата выдачи документа
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(prettifyDate(data?.issueDate ?? null))}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Кем выдан документ
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.issuedBy)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Адрес регистрации / адрес проживания
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.address)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Номер телефона
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.phone)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Гражданство
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.country)}
                </Typography>
              ),
            },
          },
        },
      ]}
    />
  </>
);
