// CheckboxGroup.tsx
import React from 'react';
import { Checkbox, CheckboxLabel } from './styles';

type CheckboxGroupProps = {
  name: string;
  options: string[];
  selectedOptions: string[];
  translations?: Record<string, string>;
  onChange: (name: string, selectedValues: string[]) => void;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  name,
  options,
  selectedOptions,
  onChange,
  translations,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let newSelectedOptions = [...selectedOptions];

    if (checked) {
      newSelectedOptions.push(value);
    } else {
      newSelectedOptions = newSelectedOptions.filter(
        (option) => option !== value,
      );
    }

    onChange(name, newSelectedOptions);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      {options.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            name={name}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={handleCheckboxChange}
          />
          {translations ? translations[option] || option : option}
        </CheckboxLabel>
      ))}
    </div>
  );
};
