import apiService from '#services/api';
import debug from '#services/debug.service';
import {
  ListedSettingResponseDtoType,
  PaginationRequestDto,
  PaginationResponseDto,
  SettingResponseDtoType,
  UpdateSettingRequestDtoType,
} from 'types';

export class SettingsService {
  private readonly API_ROUTES = {
    getFilteredSettings: 'settings/',
    settingByKey: (key: string) => `settings/${key}`,
  };

  public async updateSettingByKey(
    key: string,
    data: UpdateSettingRequestDtoType,
  ): Promise<SettingResponseDtoType> {
    try {
      const result = await apiService.request.patch(
        this.API_ROUTES.settingByKey(key),
        {
          headers: {
            'content-type': 'application/json',
          },
          json: data,
        },
      );

      debug.success('🧑🏻‍💻 ~ SettingsService ~ updateSetting ~ result: success');

      const json = await result.json();

      return json as SettingResponseDtoType;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ SettingsService ~ updateSetting ~ err:', err);

      throw err;
    }
  }

  public async getFilteredSettings(
    searchParams: PaginationRequestDto,
  ): Promise<PaginationResponseDto<ListedSettingResponseDtoType>> {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.getFilteredSettings,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams,
        },
      );

      const result: PaginationResponseDto<ListedSettingResponseDtoType> =
        await request.json();

      debug.success(
        '🧑🏻‍💻 ~ SettingsService ~ getFilteredSettings ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ SettingsService ~ getFilteredSettings ~ err:', err);

      throw err;
    }
  }

  public async getSettingByKey(key: string) {
    try {
      const result = await apiService.request.get(
        this.API_ROUTES.settingByKey(key),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const json: SettingResponseDtoType = await result.json();

      debug.success('🧑🏻‍💻 ~ SettingsService ~ getSetting ~ result:', json);

      return json;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ SettingsService ~ getSetting ~ err:', err);

      throw err;
    }
  }
}
