import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { StatusBadge } from '#components';
import { notAvailableValue } from '#utils';
import { FranchisorPhoneTypeEnumStatusBadgeMap } from 'pages/FranchiserShop/constants';
import { FranchisorPhoneResponseDto, FranchisorPhoneTypeEnum } from 'types';

type PhonesContainerProps = {
  data?: FranchisorPhoneResponseDto[];
};

export const PhonesContainer: FC<PhonesContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Телефоны организации
    </Typography>
    <Table
      header={{
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Тип телефона
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Номер
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={TypographyWeight.Bold}
              >
                Дополнительное описание
              </Typography>
            ),
          },
        },
      }}
      rows={
        data?.map((phone, index) => ({
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Телефон {index + 1}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <StatusBadge<typeof FranchisorPhoneTypeEnum>
                  {...{
                    value: phone.type as FranchisorPhoneTypeEnum,
                    data: FranchisorPhoneTypeEnumStatusBadgeMap,
                  }}
                />
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(phone.phone)}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(phone.description)}
                </Typography>
              ),
            },
          },
        })) || []
      }
    />
  </>
);
