export enum OrderPOSStatusLabels {
  NEW = 'В обработке',
  UNKNOWN = 'Неизвестен',
  ACCEPTED_BY_RESTAURANT = 'Приняли',
  POSTPONED = 'Ожидает очереди',
  COOKING = 'Готовится',
  READY = 'Готов',
  TAKEN_BY_COURIER = 'Передан курьеру',
  DELIVERED = 'Доставлен',
  CANCELLED = 'Отменен',
}

export enum OrderInternalStatusLabels {
  NEW = 'Создаётся',
  COMPLETED = 'Завершен',
  IN_PROGRESS = 'В процессе',
  CANCELED = 'Оменен',
  FAILED = 'Завершен с ошибкой',
}
