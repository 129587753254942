import styled from 'styled-components';
import {
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';
import { colors } from './variables';

interface LabelProps {
  required?: boolean;
}

export const Input = styled.input`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Light};
  padding: 8px 12px;
  border: 1px solid ${colors.primary._40};
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  color: ${colors.black._100};
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: ${colors.primary._80};
    box-shadow: 0 0 0 2px ${colors.primary._20};
  }

  &::placeholder {
    color: ${colors.black._20};
  }

  &:disabled {
    background-color: ${colors.black._05};
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Light};
  padding: 8px 12px;
  border: 1px solid ${colors.primary._40};
  border-radius: 4px;
  outline: none;
  color: ${colors.black._100};
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.black._00};

  &:focus {
    border-color: ${colors.primary._40};
    box-shadow: 0 0 0 2px ${colors.primary._20};
  }
`;

export const Button = styled.button`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Medium};
  padding: 10px 16px;
  background-color: ${colors.primary._05};
  color: ${colors.black._100};
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${colors.primary._05};
  }

  &:active {
    background-color: ${colors.primary._05};
  }

  &:disabled {
    background-color: ${colors.black._05};
    cursor: not-allowed;
  }
`;

export const TextArea = styled.textarea`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Light};
  padding: 8px 12px;
  border: 1px solid ${colors.primary._40};
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  color: ${colors.black._100};
  width: 100%;
  box-sizing: border-box;
  resize: vertical;

  &:focus {
    border-color: ${colors.primary._40};
    box-shadow: 0 0 0 2px ${colors.primary._20};
  }

  &::placeholder {
    color: ${colors.black._20};
  }
`;

export const Label = styled.label<LabelProps>`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Medium};
  font-size: 14px;
  color: ${colors.black._100};
  display: block;
  margin: 8px 0px;

  &::after {
    content: ${({ required }) => (required ? "'*'" : "''")};
    color: red;
    margin-left: 4px;
  }
`;

export const ErrorMessage = styled.div`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Light};
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h2<LabelProps>`
  font-family: ${TypographyFamily.FactorA};
  font-weight: ${TypographyWeight.Bold};
  font-size: ${TypographySize.l};
  color: ${colors.black._100};
  margin-bottom: 8px;

  &::after {
    content: ${({ required }) => (required ? "'*'" : "''")};
    color: red;
    margin-left: 4px;
  }
`;

export const SubSection = styled.div`
  margin: 24px 0px;
`;
