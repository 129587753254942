import { FC } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Input, Select, TextArea, Label, ErrorMessage } from 'styles';

// Текстовое поле
const CustomTextWidget: FC<WidgetProps> = ({
  id,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  placeholder,
  rawErrors,
}) => (
  <div>
    <Label htmlFor={id} required={required}>
      {label}
    </Label>
    <Input
      id={id}
      value={value || ''}
      disabled={disabled || readonly}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
    {rawErrors && rawErrors.length > 0 && (
      <ErrorMessage>{rawErrors.join(', ')}</ErrorMessage>
    )}
  </div>
);

// Поле select
export const CustomSelectWidget: FC<WidgetProps> = ({
  id,
  value,
  required,
  disabled,
  readonly,
  label,
  options,
  onChange,
}) => (
  <div>
    <Label htmlFor={id} required={required}>
      {label}
    </Label>
    <Select
      id={id}
      value={value || ''}
      disabled={disabled || readonly}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.enumOptions?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </div>
);

// Текстовая область
export const CustomTextareaWidget: FC<WidgetProps> = ({
  id,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
}) => (
  <div>
    <Label htmlFor={id} required={required}>
      {label}
    </Label>
    <TextArea
      id={id}
      value={value || ''}
      disabled={disabled || readonly}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

export const settingsJsonSchemaWidgets = {
  TextWidget: CustomTextWidget,
  SelectWidget: CustomSelectWidget,
  TextareaWidget: CustomTextareaWidget,
};
