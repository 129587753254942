import { FC } from 'react';
import { ButtonContainer } from './styles';
import { Button, Table, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { ListedSettingResponseDtoType } from 'types';

type SettingsTablePropsType = {
  data: ListedSettingResponseDtoType[];
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number, size: number) => void;
  isLoading: boolean;
  onEdit: (key: string) => void;
};

export const SettingsTable: FC<SettingsTablePropsType> = ({
  data,
  totalPages,
  currentPage,
  onPageChange,
  isLoading,
  onEdit,
}) => (
  <Table
    {...{
      loading: isLoading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Ключ
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Описание
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Значение
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
          },
        },
      },
      rows: data?.map((setting) => ({
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(setting.key)}
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(setting.title)}
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(setting.valueBody.value)}
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <ButtonContainer>
                <Button
                  label="Редактировать"
                  variant={ButtonVariant.Primary}
                  onClick={() => onEdit(setting.key)}
                  size={ButtonSize.Small}
                  isLoading={isLoading}
                />
              </ButtonContainer>
            ),
          },
        },
      })),
      currentPage,
      totalPages,
      onPageChange,
    }}
  />
);
