import apiService from '#services/api';
import debug from '#services/debug.service';
import { ErrorMessageResponseType, PaginationResponseDto } from 'types';
import { GetFilteredErrorsRequestDTO } from 'types/dto/errors/get-filtered-errors.request.dto';

export class ErrorsService {
  private readonly API_ROUTES = {
    errors: 'errors/',
  };

  public async getFilteredErrors(
    searchParams: GetFilteredErrorsRequestDTO,
  ): Promise<PaginationResponseDto<ErrorMessageResponseType>> {
    try {
      const request = await apiService.request.get(this.API_ROUTES.errors, {
        headers: {
          'content-type': 'application/json',
        },
        searchParams,
      });

      const result: PaginationResponseDto<ErrorMessageResponseType> =
        await request.json();

      debug.success('🧑🏻‍💻 ~ ErrorsService ~ getErrors ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ ErrorsService ~ getErrors ~ err:', err);

      throw err;
    }
  }
}
