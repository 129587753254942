import { FieldTemplateProps } from '@rjsf/utils';

export const JsonSchemaFieldTemplate = ({
  classNames,
  hidden,
  children,
  description,
  help,
}: FieldTemplateProps) => {
  if (hidden) {
    return <div style={{ display: 'none' }}>{children}</div>;
  }

  return (
    <div className={classNames}>
      {children}
      {description}
      {help}
    </div>
  );
};
