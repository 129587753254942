import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue, prettifyDate } from '#utils';
import { FranchisorShopResponseDto } from 'types';

type CommonInfoContainerProps = {
  data?: FranchisorShopResponseDto;
};

export const CommonInfoContainer: FC<CommonInfoContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Общая информация
    </Typography>
    <Table
      header={{ cells: {} }}
      rows={[
        {
          cells: {
            0: {
              width: 464,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Идентификатор магазина франчайзера
                </Typography>
              ),
            },
            1: {
              width: 116,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.id)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Идентификатор магазина франчайзера во внешней системе
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.externalId)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Полное наименование организации
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.fullName)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Сокращенное наименование организации
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.name)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Название магазина в СМС и на странице проверки 3DS на
                  иностранном языке
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.billingDescriptor)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Общероссийский классификатор видов экономической деятельности
                  (ОКВЭД)
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.acea)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Основной государственный регистрационный номер (ОГРН)
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.msrn)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Идентификационный номер налогоплательщика (ИНН)
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.tin)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Орган государственной регистрации
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.registrationDepartment)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Дата присвоения ОГРН
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(
                    prettifyDate(data?.registrationDate ?? null),
                  )}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Email, на который будет выполняться отправка реестров
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.receivingEmail)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Сведения о величине зарегистрированного и оплаченного
                  уставного (складочного) капитала или величине уставного фонда,
                  имущества
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.assets)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Сайт франчайзера
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.url)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Основные виды деятельности
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.primaryActivities)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Комментарий
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.comment)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Электронная почта франчайзера
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.shopEmail)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 464,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  MCC-код торговой группы
                </Typography>
              ),
            },
            1: {
              width: 116,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.mcc)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 464,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Код причины постановки на учёт (КПП)
                </Typography>
              ),
            },
            1: {
              width: 116,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.rrc)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 464,
              align: TableCellAlign.Right,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Является ли резидентом страны
                </Typography>
              ),
            },
            1: {
              width: 116,
              align: TableCellAlign.Center,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {data?.isResident ? 'Да' : 'Нет'}
                </Typography>
              ),
            },
          },
        },
      ]}
    />
  </>
);
