import { StatusBadgeVariant } from '#components.types';
import {
  ExternalDataFeeTypeEnum,
  ExternalDataOperationTypeEnum,
  ExternalDataPaymentSystemEnum,
  ExternalDataTerminalTypeEnum,
  FranchisorAddressTypeEnum,
  FranchisorPhoneTypeEnum,
} from 'types';

export const ExternalDataFeeTypeEnumStatusBadgeMap = {
  [ExternalDataFeeTypeEnum.DOWN]: {
    label: 'DOWN',
    variant: StatusBadgeVariant.Processing,
  },
  [ExternalDataFeeTypeEnum.UP]: {
    label: 'UP',
    variant: StatusBadgeVariant.Processing,
  },
};

export const ExternalDataOperationTypeEnumStatusBadgeMap = {
  [ExternalDataOperationTypeEnum.PAY]: {
    label: 'Платёж',
    variant: StatusBadgeVariant.Processing,
  },
  [ExternalDataOperationTypeEnum.ACCOUNT_VERIFICATION]: {
    label: 'Ферификация аккаунта',
    variant: StatusBadgeVariant.Processing,
  },
  [ExternalDataOperationTypeEnum.FAIL_PAY]: {
    label: 'Неудачный платёж',
    variant: StatusBadgeVariant.Processing,
  },
};

export const ExternalDataPaymentSystemEnumStatusBadgeMap = {
  [ExternalDataPaymentSystemEnum.VISA]: {
    label: 'VISA',
    variant: StatusBadgeVariant.Processing,
  },
  [ExternalDataPaymentSystemEnum.MASTERCARD]: {
    label: 'Mastercard',
    variant: StatusBadgeVariant.Processing,
  },
  [ExternalDataPaymentSystemEnum.MIR]: {
    label: 'MIR',
    variant: StatusBadgeVariant.Active,
  },
};

export const ExternalDataTerminalTypeEnumStatusBadgeMap = {
  [ExternalDataTerminalTypeEnum.NON_THREE_DS]: {
    label: 'Без 3DS',
    variant: StatusBadgeVariant.Disabled,
  },
  [ExternalDataTerminalTypeEnum.THREE_DS]: {
    label: '3DS Активирован',
    variant: StatusBadgeVariant.Active,
  },
};

export const FranchisorAddressTypeEnumStatusBadgeMap = {
  [FranchisorAddressTypeEnum.ACTUAL]: {
    label: 'Фактический',
    variant: StatusBadgeVariant.Active,
  },
  [FranchisorAddressTypeEnum.LEGAL]: {
    label: 'Юридический',
    variant: StatusBadgeVariant.Warning,
  },
  [FranchisorAddressTypeEnum.POSTAL]: {
    label: 'Почтовый',
    variant: StatusBadgeVariant.Processing,
  },
  [FranchisorAddressTypeEnum.OTHER]: {
    label: 'Прочий',
    variant: StatusBadgeVariant.Disabled,
  },
};

export const FranchisorPhoneTypeEnumStatusBadgeMap = {
  [FranchisorPhoneTypeEnum.COMMON]: {
    label: 'Основной',
    variant: StatusBadgeVariant.Active,
  },
  [FranchisorPhoneTypeEnum.FAX]: {
    label: 'Факс',
    variant: StatusBadgeVariant.Processing,
  },
  [FranchisorPhoneTypeEnum.OTHER]: {
    label: 'Прочий',
    variant: StatusBadgeVariant.Disabled,
  },
};
