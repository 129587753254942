import styled from 'styled-components';
import { colors } from '#styles.variables';

export const SubSectionBlock = styled.div`
  background-color: ${colors.primary._05};
  border-radius: 8px;
  padding: 24px;
  margin: 24px 0px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
