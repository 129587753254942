import styled from 'styled-components';

export const ButtonContainer = styled.div`
  max-width: 150px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
