import { FC, ReactNode } from 'react';
import { ModalContent, ModalOverlay } from './styles';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  zIndex?: number;
};

const Modal: FC<ModalProps> = ({ children, isOpen, onClose, zIndex }) => {
  if (!isOpen) {
    return <></>;
  }

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <ModalOverlay zIndex={zIndex || 1000} onClick={handleOverlayClick}>
      <ModalContent onClick={handleContentClick}>{children}</ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
