import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue, prettifyDate } from '#utils';
import { FranchisorFounderResponseDto } from 'types';

type FoundersContainerProps = {
  data?: FranchisorFounderResponseDto | null;
};

export const FoundersContainer: FC<FoundersContainerProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Учредители (Физические лица)
    </Typography>
    {data?.individuals?.length ? (
      data?.individuals?.map((individual, index) => (
        <>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            style={{ marginTop: 40, marginBottom: 20 }}
          >
            Учредитель {index + 1}
          </Typography>
          <Table
            header={{ cells: {} }}
            rows={[
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Фамилия
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.lastName)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Имя
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.firstName)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Отчество
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.middleName)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Дата рождения
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(
                          prettifyDate(individual.birthDate ?? null),
                        )}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Место рождения
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.birthPlace)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Гражданство
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.citizenship)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Вид документа, удостоверяющего личность
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.docType)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Серия и номер документа
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.docNumber)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Дата выдачи
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(
                          prettifyDate(individual.issueDate ?? null),
                        )}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Кем выдан
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.issuedBy)}
                      </Typography>
                    ),
                  },
                },
              },
              {
                cells: {
                  0: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Light}
                      >
                        Адрес регистрации / адрес проживания
                      </Typography>
                    ),
                  },
                  1: {
                    width: 232,
                    align: TableCellAlign.Left,
                    children: (
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        weight={TypographyWeight.Medium}
                      >
                        {notAvailableValue(individual.address)}
                      </Typography>
                    ),
                  },
                },
              },
            ]}
          />
        </>
      ))
    ) : (
      <Typography size={TypographySize.m} tag={TypographyTag.span}>
        Нет данных
      </Typography>
    )}
  </>
);
