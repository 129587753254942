import { FC } from 'react';
import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { FranchisorBankAccountResponseDto } from 'types';

type BankAccountContainerProps = {
  data?: FranchisorBankAccountResponseDto;
};

export const BankAccountContainer: FC<BankAccountContainerProps> = ({
  data,
}) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginTop: 40, marginBottom: 20 }}
    >
      Реквизиты партнера маркетплейса для перечисления возмещения
    </Typography>
    <Table
      header={{ cells: {} }}
      rows={[
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Расчетный или казначейский счет
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.account)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Корреспондентский счет
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.korAccount)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Наименование банка
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.bankName)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Банковский идентификационный код
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.bic)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Назначение платежа
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.details)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Код бюджетной классификации
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.bcc)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Общероссийский классификатор территорий муниципальных
                  образований
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.actme)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Отчисления в пользу маркетплейса, % от суммы операции
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.tax)}
                </Typography>
              ),
            },
          },
        },
        {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  Отчисления в пользу маркетплейса фиксированной минимальной
                  комиссии
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Medium}
                >
                  {notAvailableValue(data?.taxFix)}
                </Typography>
              ),
            },
          },
        },
      ]}
    />
  </>
);
