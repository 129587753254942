enum IconsNames {
  cross = 'cross',
  eye = 'eye',
  'closed-eye' = 'closed-eye',
  user = 'user',
  logout = 'logout',
  'arrow-right' = 'arrow-right',
  'arrow-left' = 'arrow-left',
  'arrow-down' = 'arrow-down',
  users = 'users',
  info = 'info',
  location = 'location',
  notification = 'notification',
  coupons = 'coupons',
  chat = 'chat',
  document = 'document',
  magnifier = 'magnifier',
  orders = 'orders',
  onbording = 'onbording',
  shop = 'shop',
  settings = 'settings',
  't-bank' = 't-bank',
}

export default IconsNames;
